@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: comics;
  src: url("./img/luckydemo.ttf");
}

.fuente-comics {
  font-family: comics;

}

.footer {
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 70px;
  border-top: 2px solid #043fb5;
  z-index: 2000;
}

.container2 {
  height: 100px;
  background-image: linear-gradient(to bottom, #09096c, rgb(48, 78, 197));
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  color: #fff;
  box-shadow: 0 0 0.5rem saddlebrown;
}
.imagen {
  width: 160px; /* Ajusta el tamaño de la imagen según sea necesario */
  animation: crecerDisminuir 1.5s infinite; /* Animación continua */
}
.imagen3 {
  width: 40px; /* Ajusta el tamaño de la imagen según sea necesario */
  animation: crecerDisminuir 1.5s infinite; /* Animación continua */
}

.imagent {
  width: 60px; /* Ajusta el tamaño de la imagen según sea necesario */
  animation: crecerDisminuir 1.5s infinite; /* Animación continua */
}

@keyframes crecerDisminuir {
  0%,
  100% {
    transform: scale(1); /* Tamaño original */
  }
  50% {
    transform: scale(1.1); /* Tamaño aumentado */
  }
}

/* Core styles/functionality */
.tab {
  position: relative;
}
.tab input {
  position: absolute;
  opacity: 0;
  z-index: -1;
}
.tab__content {
  max-height: 0;
  overflow: hidden;
  transition: all 0.35s;
}
.tab input:checked ~ .tab__content {
  max-height: 100rem;
}

/* Visual styles */
.accordion {
  color: #227093;
  border: 2px solid;
  border-radius: 0.5rem;
  overflow: hidden;
}
.tab__label,
.tab__close {
  display: flex;
  color: white;
  background: #227093;
  cursor: pointer;
}
.tab__label {
  justify-content: space-between;
  padding: 1rem;
}
.tab__label::after {
  content: "\276F";
  width: 1em;
  height: 1em;
  text-align: center;
  transform: rotate(90deg);
  transition: all 0.35s;
}
.tab input:checked + .tab__label::after {
  transform: rotate(270deg);
}
.tab__content p {
  margin: 0;
  padding: 1rem;
}
.tab__close {
  justify-content: flex-end;
  padding: 0.5rem 1rem;
  font-size: 0.75rem;
}
.accordion--radio {
  --theme: #2563eb;
}

/* Arrow animation */
.tab input:not(:checked) + .tab__label:hover::after {
  animation: bounce 0.5s infinite;
}
@keyframes bounce {
  25% {
    transform: rotate(90deg) translate(0.25rem);
  }
  75% {
    transform: rotate(90deg) translate(-0.25rem);
  }
}


.rotar {
  animation: girar 5s linear infinite; /* Duración de 5 segundos, animación lineal e infinita */
}

@keyframes girar {
  from {
      transform: rotate(0deg);
  }
  to {
      transform: rotate(360deg);
  }
}


.contenedoricono {
  position: absolute;
  left: 20%;
  top: 10%;
}

.moneda {

  width: 70px;
  height: 70px;
  position: relative;
  border-radius: 20%;
  animation: salto 0.8s infinite;
}

.moneda::before {
  content: '';
  display: inline-block;
 
  width: 70px;
  height: 70px;
  position: absolute;
  border-radius: 20%;
  left: 10px;
  top: 0px;
}

.moneda::after {
  content: '';
  display: inline-block;
  background-image: url('https://royalinsurancellc.com/bingo/atrass.png');
  width: 70px;
  height: 70px;
  position: absolute;
  background-size: 65px 65px;
  background-repeat: no-repeat;
}

@keyframes salto {
  0%, 100% {top: 0;}
  15% {top: -5px;}
}



.text-border {
  
  color: rgb(4, 109, 4); /* Color del texto */
  text-align: center;
  font-family: comics; /* Cambia la fuente si es necesario */
  text-shadow: 
    1px 1px 0 white,   /* Sombra derecha */
    -1px -1px 0 white, /* Sombra izquierda */
    1px -1px 0 white,  /* Sombra superior derecha */
    -1px 1px 0 white,  /* Sombra inferior izquierda */
    0px 1px 0 white,   /* Sombra inferior */
    0px -1px 0 white;  /* Sombra superior */
}

.image-container {
  position: relative;
  width: 100px; /* Ajusta el ancho del contenedor según sea necesario */
  height: 100px; /* Ajusta la altura del contenedor según sea necesario */
  overflow: hidden; /* Oculta la parte de la imagen que sale del contenedor */
  border: 2px solid #000; /* Opcional: agrega un borde para visualizar el contenedor */
}

.moving-image {
  position: absolute;
  width: 60px; /* Ajusta el tamaño de la imagen según sea necesario */
  animation: moveImage 1s linear infinite; /* Llama a la animación */
}

@keyframes moveImage {
  0% {
    left: 0; /* Comienza en el lado izquierdo del contenedor */
  }
  50% {
    left: 30px; /* Se mueve 30 píxeles a la derecha */
  }
  100% {
    left: 0; /* Regresa al lado izquierdo del contenedor */
  }
}



.radio-wrapper-21 label {
  background: #a6e5e0;
  padding: 11px;
  border: none;
  width: 100%;
  border-radius: 6px;
  cursor: pointer;
  display: inline-block;
  box-sizing: border-box;
  text-align: left;
  box-shadow: 0 0 0 1px #d8dee4;
  transition: all 0.2s ease;
}
.radio-wrapper-21 label:hover {
  background: #a6e5e0;
}
.radio-wrapper-21 input {
  clip: rect(0, 0, 0, 0);
  border-width: 0;
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  white-space: nowrap;
  width: 1px;
}
.radio-wrapper-21 input:checked ~ label {
  box-shadow: 0 0 0 1.5px #675dff;
  color: white;
  background-color: #a52a2a;
}
.radio-wrapper-21 input:checked ~ label .description {

  color: white;
}
.radio-wrapper-21 input:focus ~ label {
  box-shadow: 0 0 0 1.5px #675dff, 0 0 0 4px rgba(1, 150, 237, .36);
}
.radio-wrapper-21 input ~ label .title {
  font-weight: 600;
  margin-bottom: .5em;
}
.radio-wrapper-21 input ~ label .title,
.radio-wrapper-21 input ~ label .description {
  display: block;
  font-size: 16px;
}
.radio-wrapper-21 input ~ label .description {
  color: #596171;
  font-size: 14px;
}



.animacion {
  animation: parpadeo 1s infinite;
}

@keyframes parpadeo {
  0% {
      background-color: #a8e6cf; /* Verde claro */
  }
  50% {
      background-color: #4caf50; /* Verde oscuro */
  }
  100% {
      background-color: #a8e6cf; /* Verde claro */
  }
}